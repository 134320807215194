.error-boundary-page-wrapper {
  width: 100%;
  height: calc(100vh - 104px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  @media screen and (max-width: 1000px) {
    height: calc(100vh - 176.5px);
  }
  .body-error-boundary {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .icon-error-boundary {
      width: 537px;
      height: 367px;
      margin-bottom: 43px;
      @media (max-width: 1000px) {
        width: 269.23px;
        height: 184px;
        margin-bottom: 31px;
      }
    }
    .title {
      font-family: "Montserrat";
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #1c1d20;
      margin-bottom: 13px;
      max-width: 239px;
      @media (max-width: 1000px) {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 10px;
        max-width: 186px;
      }
    }
    .description {
      font-family: "Source Sans Pro";
      font-weight: 400;
      font-size: 17px;
      line-height: 21px;
      color: #333539;
      margin-bottom: 22px;
      max-width: 403px;
      @media (max-width: 1000px) {
        font-size: 13.8056px;
        line-height: 17px;
        margin-bottom: 27px;
        max-width: 327px;
      }
    }
    .btn-try-again {
      display: flex;
      align-items: center;
      background: #ffffff;
      border: 0.678947px solid #a8aaae;
      border-radius: 6.78947px;
      padding: 12.4474px 21.5px 13.5789px;
      outline: none;
      font-family: "Montserrat";
      font-weight: 700;
      font-size: 14.1447px;
      line-height: 17px;
      color: #1c1d20;
      @media (max-width: 1000px) {
        font-size: 12.5px;
        line-height: 15px;
      }
      .icon-try-again {
        width: 15px;
        height: 15px;
        margin-right: 6.72px;
        @media (max-width: 1000px) {
          width: 13px;
          height: 13px;
          margin-right: 6px;
        }
      }
    }
  }
}

.kontainer-popup-general-apl-arkademi {
    position: fixed;
    // tambahan
    display: flex;
    justify-content: center;
    align-items: center;
    // end of tambahan
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #00000061;
    overflow-y: auto;
    z-index: 60;

    .kontainer-isi-popup {
        position: absolute;
        // left: 50%;
        // top: 50%;
        // transform: translate(-50%, -50%);
        // background-color: #FFFFFF;
        width: fit-content;
        height: fit-content;
    }
}
.kontainer-seo-judul-apl-arkademi {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}

.kontainer-seo-konten-apl-arkademi {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}

body {
  font-family: Montserrat;
}
